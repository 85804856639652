import { Page } from '@repo/api-types/generated';

export const RenderCustomPageContent = ({ page }: {
  page: Page;
}) => {
  const customCSS = page.pageCustomCSS ?? '';

  return (<>
    {customCSS && <style>{customCSS}</style>}
  </>);
};
