'use client';

import SuccessAlert from '@/components/alerts/success-alert';
import FullPageLoader from '@/components/loaders/full-page-loader';
import ProductCard from '@/components/products/product-card';
import SectionBackground from '@/components/sections/section-background';
import useRedeemGiftCardDialog from '@/components/store/gifting/redeem-dialog';
import { RenderCustomPageContent } from '@/lib/page-content';
import { cn } from '@/lib/utils';
import { Page, SiteConfiguration } from '@repo/api-types/generated';
import { enabledProductsSpread } from '@repo/config';
import React from 'react';
import { motion } from 'framer-motion';
import SpotlightBackgroundContent from '@/components/ui/interactive/spotlight-bg-content';

const StorePageInner = ({ products, siteConfiguration, page }: {
  products: Awaited<ReturnType<typeof enabledProductsSpread>>;
  siteConfiguration: SiteConfiguration;
  page?: Page;
}) => {
  const {
    Component: RedeemGiftCardDialog,
    message,
  } = useRedeemGiftCardDialog();

  return (
    <main className={cn(
      'relative z-10 flex flex-col self-stretch w-full justify-center overflow-hidden',
      'py-8 px-1 sm:px-2 md:px-4 lg:px-8'
    )} style={{
      backgroundColor: 'hsl(var(--store-page-background))',
    }}>
      {page && <RenderCustomPageContent page={page} />}
      {page?.backgroundImage?.data && (
        <SectionBackground
          background={page.backgroundImage.data}
          backgroundOverlayOpacity={page.backgroundOverlayOpacity}
        />
      )}

      <h1 className='text-4xl font-bold text-center sr-only'>Store</h1>

      <div className={cn(
        'mgsm-products-wrapper',
        'max-w-screen-2xl w-full mx-auto flex flex-row flex-wrap items-stretch justify-center',
        'gap-4 pb-4', // gap-8 prevents 4 cards on 1 row
      )}>
        {products?.map((e) => (<ProductCard
          key={e.slug}
          product={e}
          className=''
          siteConfiguration={siteConfiguration}
        />))}
      </div>

      {message && <SuccessAlert title='Redirecting...' description={message} className='max-w-[350px] mx-auto mb-8' />}
      {message && <FullPageLoader />}

      <motion.div className={cn(
        'mgsm-gift-card-redeem-wrapper group',
        'relative z-[2] flex flex-col gap-1 max-w-lg mx-auto bg-background',
        'border rounded-xl lg:rounded-2xl overflow-hidden',
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      >
        <div className='absolute z-[-1] inset-0 bg-primary/10' />
        <SpotlightBackgroundContent wrapperClassName='mgsm-redeem-spotlight-background h-full'>
          <div className={cn(
            'relative w-full h-full px-4 pt-5 pb-4'
          )}>
            <h2 className='absolute top-0 left-0 z-10 bg-primary text-white font-bold px-2 py-1 rounded-t-xl w-full text-center'>
              Redeem a Gift Card
            </h2>
            <p className='text-center max-w-lg mx-auto mt-6'>
              Did you receive a gift card from a friend? Redeem it here by clicking the button below, and entering the code.
            </p>
            <div className='mt-3 flex justify-center'>
              <RedeemGiftCardDialog />
            </div>
          </div>
        </SpotlightBackgroundContent>
      </motion.div>
    </main>
  );
};

export default StorePageInner;
