'use client';

import React from 'react';

import { useHybridDialog } from '@/components/ui/hybrid-dialog';
import { GiftIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { labelClassName } from '../purchase/steps';
import { cn } from '@/lib/utils';
import ErrorAlert from '@/components/alerts/error-alerts';
import FullPageLoader from '@/components/loaders/full-page-loader';
import { APIGiftCardResponse } from '@repo/api-types';
import { orderDetailsLocalStorageKey } from '../purchase/step-references';
import { useRouter } from 'nextjs-toploader/app';

const useRedeemGiftCardDialog = () => {
  const router = useRouter();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [message, setMessage] = React.useState<string | null>(null);

  const {
    Component: GiftCardDialog,
    setOpen,
  } = useHybridDialog({
    titleContent: 'Redeem a Gift Card Code',
    triggerContent: (<Button className='mgsm-redeem-gift-dialog-trigger py-6 text-white font-bold' variant={'secondary'}>
      <GiftIcon className='w-8 h-8 mr-2' />
      <span>Redeem Gift Card</span>
    </Button>),
    closeContent: (<Button type="button" variant="secondary"><span className='text-white font-semibold'>
      Cancel
    </span></Button>),
    descriptionContent: 'Please enter your gift card code below to redeem it.',
    footerContent: (<>
      <Button className='text-white font-semibold' onClick={() => {
        redeemGiftCard();
      }}>Redeem</Button>
    </>),
    children: (<form className='mgsm-redeem-gift-dialog-content w-full flex flex-col gap-4' id='gift-card-form'>
      <input type='text' required
        className={cn(
          labelClassName,
          'p-2 w-full h-auto',
        )}
        placeholder='Enter your gift card code'
      />

      {loading && <FullPageLoader />}
      {error && <ErrorAlert description={error} className='bg-gray-100 dark:bg-gray-900 w-full md:max-w-[375px]' />}
    </form>),
  });

  const redeemGiftCard = async () => {
    setLoading(true);
    setError(null);
    setMessage(null);

    const code = document.getElementById('gift-card-form')?.querySelector('input')?.value;
    if (!code) {
      setError('Please enter a gift card code.');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`/api/store/gift-cards/${code}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const json = await response.json();
      if (!response.ok) {
        throw new Error(json.message);
      }

      if (!json.data || !json.data.gift_card || !json.data.product) {
        throw new Error('Invalid response from the server.');
      }

      const { gift_card, product } = json.data as APIGiftCardResponse;

      if (gift_card.redeem_order_id) {
        setError(`This gift card has already been redeemed for a ${product.name} product.`);
        return;
      }

      if (gift_card.used === gift_card.amount) {
        setError('This gift card has already been fully redeemed.');
        return;
      }

      if (gift_card.used > 0) {
        setError('This gift card has already been partially redeemed.');
        return;
      }

      const lsKey = `${orderDetailsLocalStorageKey}-${product.slug}`;
      const fromLS = localStorage.getItem(lsKey);
      if (fromLS) {
        const parsed = JSON.parse(fromLS);
        localStorage.setItem(lsKey, JSON.stringify({
          ...parsed,
          purchasingAsGift: false,
          redeemGiftCardCode: code,
        }));
      }
      else {
        localStorage.setItem(lsKey, JSON.stringify({
          purchasingAsGift: false,
          redeemGiftCardCode: code,
        }));
      }

      const productURL = `/store/${product.slug}/purchase`;

      setMessage(`Gift card successfully redeemed! Redirecting to the ${product.name} purchase page in 1.5 seconds...`);
      setOpen(false);
      setTimeout(() => {
        router.push(productURL);
      }, 1000 * 1.5);

    } catch (err) {
      const errMsg = err instanceof Error ? err.message : `An error occurred: ${err}`;
      setError(errMsg);
    } finally {
      setLoading(false);
    }
  };

  return {
    Component: GiftCardDialog,
    message,
  };
};

export default useRedeemGiftCardDialog;
