import { Button, ButtonProps } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { PopulatedProduct, PopulatedProductVariant, SiteConfiguration } from '@repo/api-types/generated';
import Link from 'next/link';
import { HTMLAttributes } from 'react';

export type RenderStepProps = {
  nextStep: Step | null;
  onCompleteStep: (id: number) => void;
  product: PopulatedProduct | PopulatedProductVariant;
  siteConfiguration: SiteConfiguration;
}

export type Step = {
  id: number;
  title: string;
  localStorageRef: string;
  checkLocalStorage: (product: PopulatedProduct | PopulatedProductVariant) => boolean | unknown;
  RenderNavListItem: (props: HTMLAttributes<HTMLLIElement> & StepNavTypes) => React.ReactElement;
  navChildren?: React.ReactNode;
  RenderContent: (props: RenderStepProps) => React.ReactElement;
}

export type StepNavTypes = {
  stepId: number;
  currStepId: number;
  lastStepId: number;
}

export const baseClassName= 'group h-16 inline-flex items-center justify-center w-full p-5 text-gray-500 border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 bg-gray-50 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-base';
export const labelClassName = cn(
  baseClassName,
  'peer-checked:border-primary/60',
  'peer-checked:text-gray-500 dark:peer-checked:text-gray-400 '
);
export const stepAlertClassName = cn(
  'py-1 rounded-lg w-full h-full flex flex-col items-center justify-center',
);

export const stepStatusColors = ({
  loading,
  error,
  status,
}: {
  status: boolean;
  loading: boolean;
  error: string | null;
}) => {
  return loading
    ? 'border-primary/50 dark:border-primary/50'
    : error ?? status === false
      ? 'border-red-500 dark:border-red-500'
      : 'border-primary/60 dark:border-primary/60';
};

export const StepFooter = ({ className, siteConfiguration }: {
  className?: string;
  siteConfiguration: SiteConfiguration;
}) => {
  return (<p className={cn(
    'mt-4 text-sm font-light text-gray-500 dark:text-gray-400',
    className
  )}>
    Is something not right?{' '}{
      siteConfiguration.attributes.support.useMGSMChatSupport
        ? 'Chat with us by clicking the chat bubble in the bottom left corner.'
        : ''
    }
    <Link
      href={
        siteConfiguration.attributes.support.useMGSMSupport
          ? process.env.NEXT_PUBLIC_SUPPORT_URL ?? '/contact'
          : siteConfiguration.attributes.support.supportURL
      }
      className='font-medium hover:underline text-gray-600 dark:text-gray-300'
      target='_blank'
      rel='noopener noreferrer'
    >
      {
        siteConfiguration.attributes.support.useMGSMChatSupport
          ? <><br />Alternatively, submit a ticket</>
          : 'Let us know'
      }
    </Link>
    .
  </p>);
};

export type StepButtonProps = {
  nextStep: Step | null;
  children?: React.ReactNode;
  className?: string;
  onClick?: ((e: React.MouseEvent<HTMLButtonElement>) => void);
  disabled?: boolean;
} & Omit<ButtonProps, 'onClick'>;

export const stepButtonClassName = 'w-full px-5 py-2.5 sm:py-3.5 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary/80 focus:ring-4 focus:outline-none focus:ring-primary/30 dark:bg-primary dark:hover:bg-primary/80 dark:focus:ring-primary/80';
export const StepButton = ({ nextStep, children, className, onClick, disabled, ...props }: StepButtonProps) => {
  if (nextStep) return (
    <Button
      className={cn(
        stepButtonClassName,
        disabled ? 'cursor-not-allowed opacity-50 grayscale' : '',
        className
      )}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
          return;
        }
        onClick?.(e);
      }}
      {...props}
    >
      {children ?? <>Next: {nextStep.title}</>}
    </Button>
  );
  return null;
};

export const StepBackButton = ({ className, ...props }: StepButtonProps) => {
  return (<StepButton
    {...props}
    className={cn(
      'bg-gray-200 text-gray-600 dark:bg-gray-800 dark:text-gray-400',
      'hover:bg-gray-300 dark:hover:bg-gray-700',
      'hover:text-gray-700 dark:hover:text-gray-300',
      'sm:rounded-r-none',
      className
    )}
    onClick={() => window.history.back()}
  >
    Go back
  </StepButton>);
};

export const StepNavListItem = ({ children, stepId, currStepId, lastStepId, ...props }: StepNavTypes & HTMLAttributes<HTMLLIElement>) => (
  <li
    className={'whitespace-nowrap flex items-center' + (stepId === lastStepId
      ? ''
      : ' after:content-[\'\'] after:w-20 after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:flex sm:after:grow after:mx-6 xl:after:mx-10 dark:after:border-gray-700')}
    {...props}
  >
    <div className={cn(
      'flex items-center sm:block',
      stepId !== lastStepId ? 'after:content-[\'/\'] sm:after:hidden after:mx-2 after:font-light after:text-gray-200 dark:after:text-gray-500' : '',
      currStepId === stepId ? 'font-bold text-primary dark:text-primary' : '',
      currStepId > stepId ? 'text-gray-400 dark:text-gray-600' : ''
    )}>
      <div className={cn(
        'mr-2 sm:mb-2 sm:mx-auto',
        currStepId > stepId ? 'hidden' : ''
      )}>{stepId}</div>
      <svg
        className={cn(
          'w-4 h-4 mr-2 sm:mb-2 sm:w-6 sm:h-6 sm:mx-auto',
          currStepId > stepId ? 'text-gray-400 dark:text-gray-600' : 'hidden'
        )}
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
      </svg>
      {children}
    </div>
  </li>
);
