'use client';

import React from 'react';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { cmsImageUrl } from '@/lib/utils';
import { Media } from '@repo/api-types/generated';

const SectionBackground = ({
  background,
  backgroundOverlayOpacity,
}: {
  background: Media;
  backgroundOverlayOpacity?: number | null;
}) => {
  const backgroundURL = background.attributes.url;
  const resolvedURL = backgroundURL.startsWith('http') ? backgroundURL : cmsImageUrl(backgroundURL);
  const resolvedOpacity = typeof backgroundOverlayOpacity === 'number' ? backgroundOverlayOpacity : 30;
  return (<motion.div className="mgsm-section-background-wrapper absolute inset-0 z-[-2] h-full w-full"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 1.5 }}
  >
    {/* <div className="absolute inset-0 z-[8] bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-white dark:from-black from-0% to-primary/50 dark:to-primary/50 to-60%" /> */}
    <div className="mgsm-section-background-overlay-1 absolute inset-0 z-[8] bg-gradient-to-br from-transparent to-primary/75" />
    <div className="mgsm-section-background-overlay-2 absolute inset-0 z-[8] bg-white dark:bg-black" style={{
      opacity: resolvedOpacity / 100,
    }} />
    <Image
      src={resolvedURL}
      alt="Background Image"
      width={background.attributes.width}
      height={background.attributes.height}
      priority
      loading='eager'
      className='mgsm-section-background-image absolute inset-0 z-[-1] object-cover object-center pointer-events-none select-none'
      style={{
        height: '100%',
        width: '100%',
      }}
      onDragStart={(e) => e.preventDefault()}
    />
  </motion.div>);
};

export default SectionBackground;
